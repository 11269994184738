import React, { useState } from 'react';
import { Layout } from '@components';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import axios from 'axios';

const StyledContactSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 70vh;
  padding: 0;
  padding-top: 50px;
 
  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }
  * {
    box-sizing: border-box;
  }

  body {
    background: #9de0a3;
    min-width: 320px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 20px;
  }
 
  .alert {
    text-align: center;
    margin: 10px;
    border-radius: 10px;
    padding: 20px;
    background-color: #f44336;
    color: white;
  }
  .success {
    text-align: center;
    margin: 10px;
    border-radius: 10px;
    padding: 20px;
    background-color: #2FB986;
    color: white;
  }
  

  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .closebtn:hover {
    color: black;
  }
  
  .form-container {
    margin-top: 50px;
    padding: 0 10%;
    width: 100%;
    section {
      width: 100%;
      padding: 20px;
      background: var(--lightest-navy);
      margin-top: 50px;
      border-radius: 10px;
      z-index: 1000;
      backdrop-filter: saturate(180%) blur(10px);
      @media (max-width: 720px) {
        margin-top: 70px;
      }
     
      .input-container {
        label {
        font-family: var(--font-sans);
          display: block;
          margin-bottom: 5px;
          font-size: 16px;
          color:var(--green);
        }
        input,
        textarea {
          font-family: var(--font-sans);
          width: 100%;
          padding: 5px 10px;
          height: 40px;
          border-radius: 5px;
          border: none;
          background: var(--white);
          box-shadow: 2px 2px 0 #a3adcc;

          &:focus {
            outline: none;
            background: var(--white);
          }
        }
        textarea {
          height: 122px;
          padding: 15px 10px;
          resize: none;
        }
        padding: 10px;
      }
    }
    
    }
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    @media (max-width: 992px) {
      padding: 0 5%;
    }
    @media (max-width: 768px) {
      margin-bottom: 50px;
      section {
        width: 100%;
        margin: 0;
        &.left {
          margin-bottom: -30px;
        }
      }
      
      }
    }
  }
`;
const Message = ({ location }) => {
  const [email, setEmail] = useState({ emailAddress: '', subject: '', message: '' });
  const [toast, setToast] = useState(null);
  const handleEmail = () => {
    if (!(email.emailAddress.length || email.subject.length || email.message.length)) {
      setToast('blank');
      return;
    }
    let params = {
      user_id: 'user_D9efgd2iyK4Dqe200TFaE',
      service_id: 'service_vg6edw9',
      template_id: 'template_ssxohp7',
      template_params: email,
    };

    let headers = {
      'Content-type': 'application/json',
    };
    axios.post('https://api.emailjs.com/api/v1.0/email/send', params, { headers }).then(
      result => {
        setEmail({ emailAddress: '', subject: '', message: '' });
        setToast('ok');
      },
      error => {
        console.log(error);
        setToast('error');
      },
    );
    console.log(location);
  };
  return (
    <Layout location={location}>
      <Helmet title="Message" />

      <StyledContactSection>
        {/* <h2 style={{ textAlign: 'center' }}>Contact Me</h2> */}
        <div className="form-container">
          <section>
            <div className="input-container">
              <label for="address">Name</label>
              <input
                placeholder="Send 📧 for future communication"
                type="text"
                value={email.emailAddress}
                onChange={e => {
                  setEmail({ ...email, emailAddress: e.target.value });
                }}
              />
            </div>
            <div className="input-container">
              <label for="address">Subject </label>
              <input
                placeholder="Random 💭/💡 you have in your 🧠"
                type="text"
                value={email.subject}
                onChange={e => {
                  setEmail({ ...email, subject: e.target.value });
                }}
              />
            </div>
            <div className="input-container">
              <label for="comments">Message</label>
              <textarea
                placeholder="Describe your idea. 💭"
                name="comments"
                value={email.message}
                onChange={e => {
                  setEmail({ ...email, message: e.target.value });
                }}
                id=""></textarea>
            </div>
          </section>
          {toast === 'error' ? (
            <div className="alert">
              <strong>🧰 You got some Error! </strong>{' '}
              <a href={`mailto:${email}`} style={{ color: 'white' }}>
                📩yashvadaria@gmail.com
              </a>
            </div>
          ) : toast === 'ok' ? (
            <div className="success">
              <strong>✅ Success !</strong> Your mail is sent.
            </div>
          ) : toast === 'blank' ? (
            <div className="alert">
              <strong>👀 Dont be cheeky!</strong> I can see you.
            </div>
          ) : null}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button className="email-link" onClick={() => handleEmail()}>
              Send 📬
            </button>
          </div>
        </div>
      </StyledContactSection>
    </Layout>
  );
};

export default Message;
